body {
  font-family: $font-family;
  @include font-body;
  font-weight: 500;
  color: $color-black;
}

a {
  color: $color-black;
  text-decoration: none;
  // text-underline-offset: 0.3em;
  // text-decoration-color: rgba($color-green, 0.4);
  transition: all var(--transition);

  &:hover,
  &:active {
    text-decoration: none;
    // text-decoration-color: rgba($color-green, 0);
    // text-underline-offset: 0.7em;
    color: $color-error;
  }
  &:focus {
    // text-decoration: underline solid rgba(0,0,0,0.1);
    outline: none;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
  color: $color-black;
}

h1,
.uk-h1,
.h1 {
  @include font-h1;
  margin-bottom: $block-margin;

  & a {
    text-decoration: none;
  }
}

h2,
.uk-h2,
.h2 {
  @include font-h2;
  margin-bottom: $block-margin;
}

h3,
.uk-h3,
.h3 {
  @include font-h3;

  margin-bottom: $block-margin;
}

h4,
.uk-h4,
.h4 {
  @include font-body;
  font-weight: bold;
}

input,
option,
select {
  font-family: $font-family;
}

p + h3,
p + h4 {
  margin-top: percent-view(69px);
}

h3 + h3,
h4 + h4 {
  margin-top: percent-view(69px);
}

p + p {
  margin-top: $content-paragraph-margin;
}

.text {
  &-muted {
    color: rgba($color-gray, 0.6);
  }

  &-lead {
    @include font-lead;
  }
}

.text__content {
  * + p,
  * + ul,
  * + ol,
  * + dl,
  * + pre,
  * + address,
  * + fieldset,
  * + figure {
    margin-top: $content-paragraph-margin;
  }
  & > ul + *,
  & > ol + *,
  & > p + ul,
  & > p + ol {
  }

  & h1,
  & h2,
  & h3,
  & h4 {
    margin-bottom: percent-view(53px);
  }

  & ul {
    list-style-type: disc;
  }

  & ul,
  & ol {
    padding-left: $base-list-padding-left;
  }
}
