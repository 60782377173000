.index {
}

.page {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
  align-items: center;
}

.webgl {
  cursor: grab;
  user-select: none;
  -webkit-user-select: none;
}
