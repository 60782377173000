.socials {
  margin-bottom: 50px;
  display: flex;
  gap: 10px;
  justify-content: center;

  &__social {
    $social-size: responsive-value(48px, 64px);
    background-color: #fff;
    display: block;
    border-radius: $border-radius;
    width: responsive-value(64px, 90px);
    height: responsive-value(64px, 90px);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
    transition: all 0.65s $button-transition;
    flex: 0 1 auto;

    & > svg,
    & > img {
      width: $social-size;
      height: $social-size;
      // line-height: $social-size;
      border-radius: 50%;
    }

    &:hover {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
}
