*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
canvas,
img,
video {
  /* 1 */
  max-width: 100%;
  /* 2 */
  height: auto;
  /* 3 */
  box-sizing: border-box;
}
video {
  object-fit: cover;
}
/* 4 */
@supports (display: block) {
  svg {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    box-sizing: border-box;
  }
}
svg:not(:root) {
  overflow: hidden;
}

iframe {
  border: 0;
}

/*
 * Reset margin for nested lists
 */

ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}
/////

::-webkit-scrollbar {
  width: 2px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

html {
  font-size: 62.5%; // 1rem = 10px
  scroll-behavior: smooth;

  max-width: 100vw;
  overflow: hidden;
}

body {
  // font-size: var(--font-size);
  // background-image: url(../images/om.jpg);
  // line-height: var(--line-height);

  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.main {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  // margin-top: calc(-1 * #{$header-height-small});
  // // overflow: hidden;
  // @media (min-width: $breakpoint-medium) {
  //   margin-top: calc(-1 * #{$header-height});
  // }
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.container {
  flex: 0 1 auto;
  margin: 0 $container-margin;
  max-width: $container-width;

  & > section:last-child {
    margin-bottom: 0;
  }
}

a,
input,
button {
  -webkit-tap-highlight-color: transparent;
}
