.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  // background-color: $color-white;
  background: linear-gradient(180deg, rgba(27, 45, 68, 1) 0%, #1b2d44 100%);
  // backdrop-filter: blur(50px);
  justify-content: center;
  align-items: center;
  z-index: 2000;
  flex-direction: column;
  display: flex;

  opacity: 0;
  transition: opacity 0.4s ease;
  // visibility: hidden;
  pointer-events: none;

  &--active {
    opacity: 1;
    transition-duration: 0s;
    pointer-events: all;
  }

  &--light {
    background: rgba($color-black, 0.5);
  }

  &__main {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;

    @media (min-width: $breakpoint-medium) {
      width: responsive-value(190px, 476px);
      height: responsive-value(190px, 476px);
    }

    .preloader--light & {
      width: responsive-value(120px, 250px);
      height: responsive-value(120px, 250px);
    }
  }

  &__text {
    display: none;
    color: $color-error;
    text-transform: uppercase;
    margin-top: responsive-value(10px, 30px);
    // @include font-h3;

    &.visible {
      display: block;

      .preloader--light & {
        display: none;
      }
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;

    & > img,
    & > svg {
      width: 100%;
      height: 100%;
      fill: $color-error;

      .preloader--light & {
        fill: $color-white;
      }
    }
  }
  &__content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(20%);

    .preloader--light & {
      display: none;
    }
  }
  &__logo {
    margin-bottom: 15px;
  }
  &__progress {
    color: $color-error;
    font-size: 20px;
    -moz-text-fill-color: transparent;
    -moz-text-stroke-color: $color-error;
    -moz-text-stroke-width: 2px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: $color-error;
    -webkit-text-stroke-width: 2px;

    @media (min-width: $breakpoint-medium) {
      font-size: responsive-value(24px, 48px);
    }
  }
  &__bottom {
    flex: 0 0 auto;
    margin-bottom: responsive-value(15px, 50px);

    .preloader--light & {
      display: none;
    }
  }
}
