.customizer {
  $customizer-button-size: responsive-value(28px, 64px);
  $customizer-buttons-bar-radius: responsive-value(38px, 64px);

  $generate-button-size: responsive-value(36px, 64px);

  $customizer-control-item-size: responsive-value(48px, 96px);
  $customizer-control-subitem-size: responsive-value(32px, 64px);
  $customizer-control-item-radius: responsive-value(60px, 80px);

  $customizer-button-margin: responsive-value(7px, 15px);

  $control-color-size: responsive-value(18px, 36px);

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  pointer-events: none;

  & > * {
    pointer-events: all;
    user-select: none;
  }

  &__control {
    // First Level Controls
    &-buttons {
      @include dark-bg-border;
      margin-left: $customizer-button-margin;
      border-radius: $customizer-buttons-bar-radius;
      padding: responsive-value(2px, 6px);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all var(--transition);
      transition-delay: 0.2s;
      transform: translateX(0);

      &.hidden {
        transform: translateX(-#{$translate});
        opacity: 0;
        transition-delay: 0s;
      }
    }
    &-button {
      width: $customizer-button-size;
      height: $customizer-button-size;
      background: $color-gray-2;
      // border: 2px solid transparent;
      border-radius: 50%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      overflow: hidden;
      // font-size: 0;

      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
      backdrop-filter: blur(50px);

      transition: all var(--transition);

      &:hover {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      }

      &:last-child {
        margin-bottom: 0;
      }

      & > img {
        pointer-events: none;
      }

      &.hidden {
        display: none;
      }
    }

    // Second Level
    &-bars {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
    &-bar {
      position: absolute;
      z-index: 5;
      margin-left: 15px;

      // overflow-y: auto;
      transform: translateX(-#{$translate});
      opacity: 0;
      transition: all var(--transition);
      transition-delay: 0s;
      pointer-events: none;
      display: flex;
      align-items: flex-start;

      max-height: calc(var(--vh, 1vh) * 100);
      // overflow-y: scroll;
      // overflow-x: hidden;

      @media (min-width: $breakpoint-medium) {
        align-items: center;
      }

      &.visible {
        transform: translateX(0);
        opacity: 1;
        pointer-events: all;
        transition-delay: 0.2s;
      }

      &-close {
        position: absolute;
        right: 0;
        top: 0;
        padding: 12px;
        cursor: pointer;
        display: none;

        & svg {
          width: $close-button-size;
          height: $close-button-size;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      &-wrap {
        @include dark-bg-border;
        border-radius: $border-radius;
        padding: $customizer-button-margin;
        backdrop-filter: blur(50px);
        overflow: auto;
        overflow-x: hidden;
        max-height: calc(var(--vh, 1vh) * 98);
        scrollbar-width: thin;
      }

      &-items {
        display: flex;
        flex-direction: column;
      }
    }

    &-item {
      width: $customizer-control-item-size;
      height: $customizer-control-item-size;

      margin-bottom: $customizer-button-margin;
      color: $color-black;
      cursor: pointer;
      background-color: $color-gray-2;
      // padding: $block-padding;
      border: 2px solid $color-gray;
      border-radius: 50%;
      overflow: hidden;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;

      &:hover {
      }

      &.active {
        color: $color-black;
        border: 2px solid $color-black;
      }
      &:last-child {
        margin-bottom: 0;
      }

      & > * {
        pointer-events: none;
      }
    }

    &-subitem {
      width: $customizer-control-subitem-size;
      height: $customizer-control-subitem-size;
      // background-color: $color-white;
      // border-radius: 50%;
      // border: 2px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;

      @include font-small;
      font-family: $font-family-2;

      & > * {
        pointer-events: none;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: bottom;
      }

      &.active {
        border-color: $color-black;
      }
    }

    &-colors {
      @include dark-bg-border;
      padding: 0 12px 0 14px;
      height: $button-s-height;
      text-transform: uppercase;
      border-radius: $button-border-radius;
      white-space: nowrap;

      display: flex;
      align-items: center;

      &-wrap {
        display: flex;
        justify-content: center;
        flex: 1 0 auto;
        gap: $customizer-button-margin;

        @media (min-width: $breakpoint-medium) {
          position: absolute;
          left: 50%;
          transform: translateX(-80%);
        }
        @media (min-width: $breakpoint-large) {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &-title {
        margin-right: 10px;
        display: none;

        @media (min-width: $breakpoint-medium) {
          display: block;
        }
      }

      &-item {
        font-size: 0;
        width: $control-color-size;
        height: $control-color-size;

        border-radius: 50%;
        overflow: hidden;
        border: 0px solid $color-white;
        margin-right: 6px;

        background-color: $color-gray;
        transition: all 0.2s ease;
        background: linear-gradient(
            149.04deg,
            rgba(255, 255, 255, 0.4) 15.14%,
            rgba(255, 255, 255, 0.096) 53.72%,
            rgba(0, 0, 0, 0.188) 91.5%
          ),
          #d90e3e;
        // box-shadow: inset -3px -3px 15px 0 rgba(0, 0, 0, 0.5), inset 3px 3px 15px 0 rgba(255, 255, 255, 0.5);
        cursor: pointer;

        &:before {
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          transform: scale(1.1);
        }

        &.active {
          border-color: $color-gray;
          border-width: 2px;
        }
      }
    }

    &-girls-params {
      &-wrap {
        position: relative;
        display: flex;
        justify-content: center;
        opacity: 1;
        transition: all var(--transition);

        &.hidden {
          opacity: 0;
          pointer-events: none;
        }
      }

      &-button {
        padding: 0 $button-round-padding !important;

        &.hidden {
          display: none;
        }

        &:hover {
          @media (min-width: $breakpoint-medium) {
            & > svg {
              margin-left: 30px;
              transform: scale(1.4) rotate(180deg);
            }
          }
        }

        & > * {
          pointer-events: none;
        }

        & > img {
          width: $button-icon-wrap-size;
          height: $button-icon-wrap-size;
          overflow: hidden;
          border-radius: 50%;
          background-color: $color-gray-2;
        }
        & > svg {
          width: $button-icon-size;
          height: $button-icon-size;
          overflow: hidden;
          border-radius: 50%;
          margin: 0 10px;
          transform: scale(1.4);
          transition: all var(--transition);
        }
      }

      &-bar {
        position: absolute;
        // left: 100%;f
        bottom: 100%;
        margin-bottom: $customizer-button-margin;
        z-index: 5;
        // margin-left: 15px;
        @include dark-bg-border;
        border-radius: $border-radius;
        display: flex;
        flex-direction: column;
        gap: 10px;

        // padding: 16px 16px;
        padding: 10px 10px 0 10px;
        backdrop-filter: blur(50px);
        opacity: 0;
        transition: all var(--transition);
        transform: translateY($translate);
        pointer-events: none;

        &.visible {
          transform: translateY(0);
          opacity: 1;
          pointer-events: all;
        }
      }
      &-clothing {
        &:last-child {
          margin-bottom: 0;
        }
        &-name {
          display: none;
          margin-bottom: 10px;
        }
      }

      &-sublist {
        display: flex;
        gap: 6px;
        border-bottom: 2px solid rgba($color-gray, 0.5);

        &:last-child {
          border-bottom: none;
        }
      }
    }
    &-pose-item {
      &.active {
        color: $color-black;
      }
    }
  }

  &__camera {
    display: none;
  }

  &-bottom {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: responsive-value(8px, 24px);
    box-sizing: border-box;
    overflow: hidden;
    user-select: none;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    pointer-events: none;

    & > * {
      pointer-events: all;
    }

    &__center {
      justify-self: center;
      display: flex;
      align-items: flex-end;
      flex: 1 1 auto;

      & > * {
        margin-right: $customizer-button-margin;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    &__right {
      justify-self: flex-end;
      display: flex;
      flex: 0 0 auto;

      & > * {
        margin-right: $customizer-button-margin;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__generate {
    // display: none;

    margin-left: 10px;

    &-button {
      width: $customizer-button-size;
      height: $customizer-button-size;
      padding: 5px;
      box-sizing: content-box;
      position: relative;
      flex: 0 0 auto;
      cursor: pointer;
      transition: transform 0.4s $button-transition;

      &:hover {
        transform: scale(1.1);
      }

      & > * {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
      }
    }
  }

  &__toggle-calendar {
    background-color: $color-white;

    &.active {
      background-color: $color-error;
    }
  }

  &__share-button {
    @media (min-width: $breakpoint-medium) {
      // max-width: $button-max-width;
    }
  }
  &__gift {
    & > * {
      pointer-events: none;
    }
    & img {
      width: 32px;
      height: 32px;
    }
  }

  &__fullscreen {
    position: absolute;
    top: $customizer-button-margin;
    right: $customizer-button-margin;
    // width: $button-s-height;
    // height: $button-s-height;
    // background-color: #f1f3fb;
    // border-radius: 50%;
    // padding: 13px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // cursor: pointer;

    & > svg {
      pointer-events: none;
      width: $button-icon-size;
      height: $button-icon-size;
    }

    &-expand {
      display: block;

      .customizer__fullscreen.active & {
        display: none;
      }
    }
    &-collapse {
      display: none;

      .customizer__fullscreen.active & {
        display: block;
      }
    }

    &.hidden {
      display: none;
    }
  }

  &__top-logo {
    flex: 0 0 auto;
    position: absolute;
    // left: 50%;
    // top: $customizer-button-margin;
    // transform: translateX(-50%);
    // width: responsive-value(130px, 280px);
    top: 0;
    left: 0;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    pointer-events: none;

    & img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: left top;
    }
  }
}
