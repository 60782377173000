.button {
  @mixin button-svg {
    & .button__icon {
      width: $button-icon-wrap-size;
      height: $button-icon-wrap-size;
      padding: 10px;
      // background: #fff;
      border-radius: 50%;
      flex: 0 1 auto;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      & svg,
      & img {
        position: absolute;
        width: $button-icon-size;
        height: $button-icon-size;
      }
    }
  }

  @include font-body;
  @include dark-bg-border;

  padding: 0 $button-padding;
  height: $button-s-height;
  text-transform: uppercase;
  border-radius: $button-border-radius;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  color: $color-button-label;

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
  transition: all 0.65s $button-transition;

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }

  & * {
    pointer-events: none;
  }

  & svg {
  }

  &--round {
    // border: none;
    max-width: $button-s-height;
    position: relative;
    // justify-content: space-between;

    padding: $button-round-padding;

    @include button-svg();

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 65px;
      height: 60px;
      // background: rgba(255, 255, 255, 0.5);
      left: -11px;
    }

    &:hover {
      @media (min-width: $breakpoint-medium) {
        max-width: $button-max-width;

        &:before {
          left: -25px;
        }
      }
    }
  }

  &--gift {
    padding: responsive-value(8px, 12px);
    // border: none;
    // width: $button-s-height;
    // justify-content: center;
    // background-color: $color-white;

    & .button__icon img {
      width: responsive-value(24px, 32px);
      height: responsive-value(24px, 32px);
    }
  }
  &--black {
    background-color: $color-dark;
    color: $color-white;
    border: none;
    border-radius: $form-element-radius;
    font-family: $font-family;
    text-align: center;
    justify-content: center;
    width: 100%;

    &:hover {
      background-color: rgba($color-dark, 0.8);
    }
  }

  &--icon {
    border: none;
    max-width: $button-s-height;

    padding: $button-round-padding;
    @include button-svg();
  }

  &__content {
    padding-right: 10px;
    // transform: translateY(1px);

    .button--round & {
      padding-right: 0px;
      padding-left: 0;
      opacity: 0;

      max-width: 0;
      flex: 0 1 auto;
      transition: all 0.59s $button-transition;
      overflow: hidden;
    }

    .button--round:hover & {
      @media (min-width: $breakpoint-medium) {
        opacity: 1;
        max-width: $button-max-width;
        padding-right: 10px;
        padding-left: 20px;
        transition: all 0.71s $button-transition;
      }
    }
  }
}
