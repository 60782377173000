.overlay-rotate {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $color-dark;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  transition: opacity var(--transition);

  &__content {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    max-width: 300px;
  }
  &__icon {
    margin-bottom: 30px;

    & > svg {
      width: 60px;
      height: 60px;
    }
  }
  &__text {
    text-align: center;
    @include font-h3;
    color: $color-white;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
