.modal {
  display: none;

  &.is-open {
    display: block;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.6);
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (min-width: $breakpoint-medium) {
      align-items: center;
    }
  }

  &__container {
    background-color: $color-gray;
    // width: 100%;
    max-width: 670px;
    max-height: calc(var(--vh, 1vh) * 100);
    border: 2px solid $color-white;
    backdrop-filter: blur(50px);

    border-radius: $border-radius;
    overflow-y: auto;
    box-sizing: border-box;
    position: relative;

    .modal--sent & {
      background-color: $color-white;
      border-color: $color-white;
      max-width: 484px;
    }
  }

  &__content {
    padding: responsive-value(16px, 24px);
    text-align: center;

    & > *:last-child {
      margin-bottom: 0;
    }

    & p {
      margin-bottom: 10px;
      font-family: $font-family-2;
    }

    &-layout {
      display: flex;
      gap: $grid-gap;
      margin-bottom: $grid-gap;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__logo {
    margin-bottom: 24px;
    text-align: center;

    & > * {
      width: 132px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 0;
    box-sizing: border-box;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  &__close {
    position: absolute;
    z-index: 2;
    width: $close-button-size;
    height: $close-button-size;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background: transparent;
    border: 0;
    transition: background-color var(--transition);

    &:hover {
      background-color: $color-white;
    }
  }

  &__socials {
    margin-bottom: 0px;
    // display: flex;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $grid-gap;
    justify-content: center;
  }

  &__social {
    $social-size: 64px;
    width: $social-size;
    height: $social-size;
    // line-height: $social-size;
    border-radius: 50%;
    background-color: #ccc;
    display: block;
  }

  &__tab {
    display: none;

    &.active {
      display: block;
    }

    &--1 {
      width: responsive-value(300px, 560px);

      @media (min-width: $breakpoint-large) {
        width: auto;
      }
    }

    &-next-button {
      width: 100%;
    }
  }

  &.micromodal-slide {
    display: none;
  }

  &.micromodal-slide.is-open {
    display: block;
  }

  &.micromodal-slide[aria-hidden='false'] .modal__overlay {
    animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &.micromodal-slide[aria-hidden='false'] .modal__container {
    animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &.micromodal-slide[aria-hidden='true'] .modal__overlay {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &.micromodal-slide[aria-hidden='true'] .modal__container {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  &.micromodal-slide .modal__container,
  &.micromodal-slide .modal__overlay {
    will-change: transform;
  }
}

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
