.share {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;

  &__logo {
    width: 200px;
    margin-bottom: 40px;
  }

  &__image {
    margin-bottom: 60px;
  }

  &__socials {
  }
}
