.form {
  $input-height: 52px;
  $input-padding: 32px;
  $row-margin: 12px;

  $left-padding: $input-height / 2;

  &__row {
    display: block;
    margin-bottom: $grid-gap;
    position: relative;

    @media (min-width: $breakpoint-small) {
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-wrap {
      display: grid;
      gap: $grid-gap;
      grid-template-columns: 1fr 1fr;
      margin-bottom: $row-margin;

      & > * {
        margin-bottom: 0;
      }
    }
  }

  &__grid {
    position: relative;
    margin-bottom: 24px;

    @media (min-width: $breakpoint-small) {
      margin-bottom: 48px;
    }
    @media (min-width: $breakpoint-medium) {
      margin-bottom: 40px;
    }

    & > * {
      @media (min-width: $breakpoint-medium) {
        width: calc(50% - #{$column-margin} / 2);
      }
    }

    &-column-2 {
      @media (min-width: $breakpoint-medium) {
        right: 0;
        top: 0;
        position: absolute;
      }
    }
  }

  &__label {
    display: block;
    top: $input-height / 6;
    margin-left: $left-padding;
    position: absolute;
    pointer-events: none;
    color: $color-gray-2;

    transition: all 0.2s ease;
    font-size: 0.8em;
    font-family: $font-family-2;
  }

  &__input,
  &__textarea,
  &__select {
    @include font-body;
    font-family: $font-family;

    height: $input-height;
    vertical-align: middle;
    max-width: 100%;
    width: 100%;
    padding: $input-height/7 $left-padding 0 $left-padding;
    background: transparent;
    color: $color-black;
    border: 2px solid rgba($color-black, 1);
    border-radius: $form-element-radius;
    box-sizing: border-box;
    box-shadow: none;

    transition: 0.2s ease;
    transition-property: all;
    transition-property: color, background-color, border;

    &:focus {
      background-color: $color-white;
      // border-color: rgba($color-gray, 0.8);
      outline: 0px solid transparent;
    }

    &:required {
      outline: none;
      box-shadow: none;
    }

    &.invalid {
      border-color: $color-error;
    }
  }
  &__select {
    padding-top: 0;
    appearance: none;
    background-image: $chevron-down;
    background-repeat: no-repeat;
    background-position: calc(100% - 32px) center;
    background-size: 16px 9px;
  }
  &__textarea {
    height: 180px;
    padding-top: $input-height / 2;

    @media (min-width: $breakpoint-small) {
      height: auto;
      min-height: 180px;
    }
  }

  &__note {
    color: $color-error;
    pointer-events: none;
    font-family: $font-family-2;
  }

  &__note,
  .validation-error {
    @include font-small;
    position: absolute;
    bottom: 9px;
    left: $left-padding;

    margin-bottom: -0.5rem;
    color: $color-error;
  }

  &__controls {
    flex: 1 1 auto;
  }

  &__submit {
    // align-items: center;
    appearance: none;
    display: flex;
    gap: $grid-gap;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: $breakpoint-small) {
      flex-direction: column;
      gap: $grid-gap;
    }
  }
  &__submit-button {
    flex: 0 0 auto;
    display: block;
    border-radius: $form-element-radius;
    // margin-bottom: 28px;
    width: 100%;
    text-align: center;

    @media (min-width: $breakpoint-small) {
    }

    @media (min-width: $breakpoint-medium) {
    }
  }
  &__submit-note {
    @include font-small;
    font-family: $font-family-2;

    color: $color-black;
    text-align: center;

    & a {
      text-decoration: underline;
    }

    @media (min-width: $breakpoint-small) {
      text-align: center;
    }
  }

  .validation-error + &__note {
    display: none;
  }

  & .form__input:placeholder-shown ~ .form__label,
  & .form__textarea:placeholder-shown ~ .form__label {
    opacity: 1;
    visibility: visible;
    top: $input-height * 0.36;
    // transform: translateY(-50%);
    font-size: 1em;
  }
  & .form__textarea:placeholder-shown ~ .form__label {
    top: $input-height / 2;
  }
  & .form__input:focus ~ .form__label,
  & .form__textarea:focus ~ .form__label {
    // top: percent-view($input-height / 1.5);
    top: $input-height * 0.15;
    // transform: translateY(0);
    font-size: 0.9em;
  }

  &-checkbox {
    & input {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      clip: rect(0 0 0 0);

      &:checked + label {
        &:before {
          border-color: $color-error;
          background-color: $color-error;
        }
        &:after {
          opacity: 1;
        }
      }
    }

    & label {
      position: relative;
      padding-left: 24px;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -0px;
        width: 16px;
        height: 16px;
        border: 2px solid $color-black;
        border-radius: 4px;
        transition: all var(--transition);
      }
      &::after {
        content: '';
        position: absolute;
        left: 2px;
        top: 4px;
        width: 12px;
        height: 6px;
        border-left: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
        transform: rotate(-45deg);
        opacity: 0;
        transition: opacity 0.1s ease;
      }
    }
  }
}
